<template>
  <div>
    <h1 class="pb-3">Additional Compliance Checks</h1>
    <div
      v-for="(additional, index) in additionalChecks"
      :key="'additonal-' + index"
    >
      <h3 class="pb-2">
        {{ additional.label }}
      </h3>

      <Checkbox
        @change="value => updateChecks(value, additional.label)"
        :selected-values="
          isVerified(additional.label) ? [additional.options[0].value] : []
        "
        :options="additional.options"
        isLarge
        isColumn
        v-if="additional.label === QUALIFICATIONS"
      />

      <Checkbox
        @change="value => updateChecks(value, additional.label)"
        :selected-values="
          isVerified(additional.label) ? [additional.options[0].value] : []
        "
        :options="additional.options"
        isLarge
        isColumn
        v-if="additional.label === HCPC"
      />

      <Checkbox
        @change="value => updateChecks(value, additional.label)"
        :selected-values="
          isVerified(additional.label) ? [additional.options[0].value] : []
        "
        :options="additional.options"
        isLarge
        isColumn
        v-if="additional.label === QTS"
      />

      <Checkbox
        @change="value => updateChecks(value, additional.label)"
        :selected-values="
          isVerified(additional.label) ? [additional.options[0].value] : []
        "
        :options="additional.options"
        isLarge
        isColumn
        v-if="additional.label === PT"
      />

      <Checkbox
        @change="value => updateChecks(value, additional.label)"
        :selected-values="
          isVerified(additional.label) ? [additional.options[0].value] : []
        "
        :options="additional.options"
        isLarge
        isColumn
        v-if="additional.label === BL"
      />

      <Checkbox
        @change="value => updateChecks(value, additional.label)"
        :selected-values="
          isVerified(additional.label) ? [additional.options[0].value] : []
        "
        :options="additional.options"
        isLarge
        isColumn
        v-if="additional.label === OCB"
      />

      <Checkbox
        @change="value => updateChecks(value, additional.label)"
        :selected-values="
          isVerified(additional.label) ? [additional.options[0].value] : []
        "
        :options="additional.options"
        isLarge
        isColumn
        v-if="additional.label === DBS"
      />

      <Checkbox
        @change="value => updateChecks(value, additional.label)"
        :selected-values="
          isVerified(additional.label) ? [additional.options[0].value] : []
        "
        :options="additional.options"
        isLarge
        isColumn
        v-if="additional.label === ST"
      />

      <Checkbox
        @change="value => updateChecks(value, additional.label)"
        :selected-values="
          isVerified(additional.label) ? [additional.options[0].value] : []
        "
        :options="additional.options"
        isLarge
        isColumn
        v-if="additional.label === HS"
      />

      <Checkbox
        @change="value => updateChecks(value, additional.label)"
        :selected-values="
          isVerified(additional.label) ? [additional.options[0].value] : []
        "
        :options="additional.options"
        isLarge
        isColumn
        v-if="additional.label === CT"
      />

      <Checkbox
        @change="value => updateChecks(value, additional.label)"
        :selected-values="
          isVerified(additional.label) ? [additional.options[0].value] : []
        "
        :options="additional.options"
        isLarge
        isColumn
        v-if="additional.label === TPC"
      />

      <Checkbox
        @change="value => updateChecks(value, additional.label)"
        :selected-values="
          isVerified(additional.label) ? [additional.options[0].value] : []
        "
        :options="additional.options"
        isLarge
        isColumn
        v-if="
          additional.label === GDPRT && getComplianceChecks(additional.label)
        "
      />

      <Checkbox
        @change="value => updateChecks(value, additional.label)"
        :selected-values="
          isVerified(additional.label) ? [additional.options[0].value] : []
        "
        :options="additional.options"
        isLarge
        isColumn
        v-if="additional.label === RF && getComplianceChecks(additional.label)"
      />

      <Checkbox
        @change="value => updateChecks(value, additional.label)"
        :selected-values="
          isVerified(additional.label) ? [additional.options[0].value] : []
        "
        :options="additional.options"
        isLarge
        isColumn
        v-if="
          additional.label === CONTRACT && getComplianceChecks(additional.label)
        "
      />

      <Checkbox
        @change="value => updateChecks(value, additional.label)"
        :selected-values="
          isVerified(additional.label) ? [additional.options[0].value] : []
        "
        :options="additional.options"
        isLarge
        isColumn
        v-if="additional.label === SMC && getComplianceChecks(additional.label)"
      />
      <Checkbox
        @change="value => updateChecks(value, additional.label)"
        :selected-values="
          isVerified(additional.label) ? [additional.options[0].value] : []
        "
        :options="additional.options"
        isLarge
        isColumn
        v-if="additional.label === EDT && getComplianceChecks(additional.label)"
      />

      <STExpiryDate
        ref="STExpiryDate"
        :data="getComplianceChecks(additional.label)"
        @onChange="
          compliance => handleComplianceChange(compliance, additional.label)
        "
        :showValidationErrors="showValidationErrors"
        label="Expiry Date"
        v-if="additional.label === ST && isVerified(additional.label)"
      />

      <HCPCNumber
        ref="HCPCNumber"
        :label="additional.label"
        :validator-name="additional.options[0].value"
        :showValidationErrors="showValidationErrors"
        :data="getComplianceChecks(additional.label)"
        v-if="additional.label === HCPC && isVerified(additional.label)"
      />

      <HCPCExpiryDate
        ref="HCPCExpiryDate"
        :data="getComplianceChecks(additional.label)"
        @onChange="
          compliance => handleComplianceChange(compliance, additional.label)
        "
        :showValidationErrors="showValidationErrors"
        label="Expiry Date"
        v-if="additional.label === HCPC && isVerified(additional.label)"
      />

      <QTSNumber
        ref="QTSNumber"
        :label="additional.label"
        :validator-name="additional.options[0].value"
        :showValidationErrors="showValidationErrors"
        :data="getComplianceChecks(additional.label)"
        v-if="additional.label === QTS && isVerified(additional.label)"
      />

      <DBSCheckDetails
        ref="dbs"
        :showValidationErrors="showValidationErrors"
        :data="getComplianceChecks(additional.label)"
        v-if="additional.label === DBS && isVerified(additional.label)"
      />

      <BarredListCheck
        ref="barredListDate"
        :data="getComplianceChecks(additional.label)"
        :showValidationErrors="showValidationErrors"
        @onChange="
          compliance => handleComplianceChange(compliance, additional.label)
        "
        label="Date conducted"
        v-if="additional.label === BL && isVerified(additional.label)"
      />

      <HSCompletionDate
        ref="hsCompletionDate"
        :data="getComplianceChecks(additional.label)"
        :showValidationErrors="showValidationErrors"
        @onChange="
          compliance => handleComplianceChange(compliance, additional.label)
        "
        label="Completion Date"
        v-if="additional.label === HS && isVerified(additional.label)"
      />

      <CTCompletionDate
        ref="ctCompletionDate"
        :data="getComplianceChecks(additional.label)"
        :showValidationErrors="showValidationErrors"
        @onChange="
          compliance => handleComplianceChange(compliance, additional.label)
        "
        label="Completion Date"
        v-if="additional.label === CT && isVerified(additional.label)"
      />

      <HSExpiryDate
        ref="HSExpiryDate"
        :data="getComplianceChecks(additional.label)"
        @onChange="
          compliance => handleComplianceChange(compliance, additional.label)
        "
        :showValidationErrors="showValidationErrors"
        label="End Date"
        v-if="additional.label === HS && isVerified(additional.label)"
      />

      <CTExpiryDate
        ref="CTExpiryDate"
        :data="getComplianceChecks(additional.label)"
        @onChange="
          compliance => handleComplianceChange(compliance, additional.label)
        "
        :showValidationErrors="showValidationErrors"
        label="End Date"
        v-if="additional.label === CT && isVerified(additional.label)"
      />

      <RFExpiryDate
        ref="RFExpiryDate"
        :data="getComplianceChecks(additional.label)"
        @onChange="
          compliance => handleComplianceChange(compliance, additional.label)
        "
        :showValidationErrors="showValidationErrors"
        label="End Date"
        v-if="additional.label === RF && isVerified(additional.label)"
      />

      <EDTCompletionDate
        ref="EDTCompletionDate"
        :data="getComplianceChecks(additional.label)"
        :showValidationErrors="showValidationErrors"
        @onChange="
          compliance => handleComplianceChange(compliance, additional.label)
        "
        label="Date completed"
        v-if="additional.label === EDT && isVerified(additional.label)"
      />

      <div class="d-flex flex-column" v-if="additional.label === GDPRT">
        <GDPRTCompletionDate
          ref="gdprtCompletionDate"
          :data="getComplianceChecks(additional.label)"
          @onChange="
            compliance => handleComplianceChange(compliance, additional.label)
          "
          :showValidationErrors="showValidationErrors"
          label="Completion Date"
          v-if="isVerified(additional.label)"
        />
        <GDPRTEndDate
          ref="gdprtEndDate"
          :data="getComplianceChecks(additional.label)"
          @onChange="
            compliance => handleComplianceChange(compliance, additional.label)
          "
          :showValidationErrors="showValidationErrors"
          label="End Date"
          v-if="isVerified(additional.label)"
        />
      </div>
      <ComplianceFiles
        ref="complianceFiles"
        v-if="isVerified(additional.label)"
        :compliance="getComplianceChecks(additional.label)"
        :required="
          [RF, CONTRACT, SMC, QUALIFICATIONS, EDT].includes(additional.label)
        "
        :showValidationErrors="
          additional.label === RF ||
          additional.label === CONTRACT ||
          additional.label === SMC ||
          additional.label === QUALIFICATIONS ||
          additional.label === EDT
            ? showValidationErrors
            : false
        "
      />
      <v-divider class="mb-2 mt-2" />
    </div>

    <div v-for="check in extendedChecks" :key="check.id">
      <h3 class="pb-2">
        {{ check.complianceCheckName }}
      </h3>
      <Checkbox
        @change="value => updateChecks(value, check.complianceCheckName)"
        :selected-values="
          isVerified(check.complianceCheckName) ? [check.options[0].value] : []
        "
        :options="check.options"
        isLarge
        isColumn
      />
    </div>
  </div>
</template>

<script>
import Checkbox from "@/components/common/Checkbox";
import DBSCheckDetails from "@/components/workers/common/DBSCheckDetails";
import { first, map, reduce, find, isEmpty, filter } from "lodash";
import HCPCNumber from "@/components/workers/compliance/components/ComplianceReferenceNumber";
import QTSNumber from "@/components/workers/compliance/components/ComplianceReferenceNumber";
import { findComplianceChecks } from "@/utils/workers";
import BarredListCheck from "@/components/workers/compliance/components/ConductedDate";
import {
  COMPLIANCE_NAMES,
  ADDITIONAL_COMPLIANCE_CHECKS,
  STANDARD_COMPLIANCE_CHECKS
} from "@/constants/workers";
import ComplianceFiles from "@/components/workers/compliance/components/ComplianceFiles";
import STExpiryDate from "@/components/workers/compliance/components/ExpiryDate";
import HCPCExpiryDate from "@/components/workers/compliance/components/ExpiryDate";
import HSCompletionDate from "@/components/workers/compliance/components/ConductedDate";
import CTCompletionDate from "@/components/workers/compliance/components/ConductedDate";
import HSExpiryDate from "@/components/workers/compliance/components/ExpiryDate";
import CTExpiryDate from "@/components/workers/compliance/components/ExpiryDate";
import GDPRTCompletionDate from "@/components/workers/compliance/components/ConductedDate.vue";
import GDPRTEndDate from "@/components/workers/compliance/components/ExpiryDate.vue";
import RFExpiryDate from "@/components/workers/compliance/components/ExpiryDate.vue";
import EDTCompletionDate from "@/components/workers/compliance/components/ConductedDate.vue";
import { GET_COMPLIANCE_CHECK_TYPES } from "@/store/modules/data/getters";
import { FETCH_COMPLIANCE_CHECK_TYPES } from "@/store/modules/data/actions";
import { createNamespacedHelpers } from "vuex";
import { DATA_NAMESPACE } from "@/store/modules/data";

const {
  DBS,
  HCPC,
  QTS,
  BL,
  OCB,
  QUALIFICATIONS,
  PT,
  ST,
  TPC,
  GDPRT,
  HS,
  CT,
  RF,
  CONTRACT,
  SMC,
  EDT
} = COMPLIANCE_NAMES;

const { mapActions, mapGetters } = createNamespacedHelpers(DATA_NAMESPACE);

export default {
  props: {
    /**
     *  Data object
     */
    currentWorker: {
      type: Object,
      required: true
    },
    showValidationErrors: Boolean,
    permissions: Object,
    isUpdate: Boolean
  },
  components: {
    Checkbox,
    DBSCheckDetails,
    HCPCNumber,
    QTSNumber,
    BarredListCheck,
    ComplianceFiles,
    STExpiryDate,
    HCPCExpiryDate,
    HSCompletionDate,
    CTCompletionDate,
    HSExpiryDate,
    CTExpiryDate,
    GDPRTCompletionDate,
    GDPRTEndDate,
    RFExpiryDate,
    EDTCompletionDate
  },
  data() {
    return {};
  },
  mounted() {
    if (!this.complianceCheckTypes || !this.complianceCheckTypes.length) {
      this.fetchComplianceCheckTypes();
    }
  },
  computed: {
    ...mapGetters({
      complianceCheckTypes: GET_COMPLIANCE_CHECK_TYPES
    }),
    additionalChecks() {
      return filter(ADDITIONAL_COMPLIANCE_CHECKS, ({ label }) =>
        this.complianceCheckTypes.includes(label)
      );
    },
    DBS() {
      return DBS;
    },
    HCPC() {
      return HCPC;
    },
    QTS() {
      return QTS;
    },
    BL() {
      return BL;
    },
    OCB() {
      return OCB;
    },
    QUALIFICATIONS() {
      return QUALIFICATIONS;
    },
    PT() {
      return PT;
    },
    ST() {
      return ST;
    },
    TPC() {
      return TPC;
    },
    HS() {
      return HS;
    },
    CT() {
      return CT;
    },
    GDPRT() {
      return GDPRT;
    },
    RF() {
      return RF;
    },
    CONTRACT() {
      return CONTRACT;
    },
    SMC() {
      return SMC;
    },
    EDT() {
      return EDT;
    },
    extendedChecks() {
      return reduce(
        this.currentWorker.complianceChecks,
        (complianceToBeAdded, compliance) => {
          if (
            !find(
              ADDITIONAL_COMPLIANCE_CHECKS,
              complianceCheck =>
                complianceCheck.label === compliance.complianceCheckName
            ) &&
            !find(
              STANDARD_COMPLIANCE_CHECKS,
              complianceCheck =>
                complianceCheck.label === compliance.complianceCheckName
            )
          ) {
            compliance.options = [
              {
                value: compliance.complianceCheckName,
                label: compliance.complianceCheckName
              }
            ];
            complianceToBeAdded.push(compliance);
          }
          return complianceToBeAdded;
        },
        []
      );
    }
  },
  methods: {
    isEmpty,
    ...mapActions({
      fetchComplianceCheckTypes: FETCH_COMPLIANCE_CHECK_TYPES
    }),
    handleChange() {
      this.$emit("onChange", this.currentWorker);
    },
    updateChecks(value, name) {
      const checked = value.length > 0;
      this.currentWorker.complianceChecks = map(
        this.currentWorker.complianceChecks,
        compliance => {
          if (compliance.complianceCheckName === name) {
            compliance.checkVerified = checked;
          }

          return compliance;
        }
      );
      if (!checked) {
        this.resetValidations();
      }
      this.$forceUpdate();
      this.handleChange();
    },
    isVerified(name) {
      const complianceCheck = findComplianceChecks(this.currentWorker, name);
      return complianceCheck.checkVerified;
    },
    getComplianceChecks(name) {
      return findComplianceChecks(this.currentWorker, name);
    },
    handleComplianceChange(newCompliance, name) {
      this.currentWorker.complianceChecks = map(
        this.currentWorker.complianceChecks,
        compliance => {
          if (compliance.complianceCheckName === name) {
            compliance = newCompliance;
          }
          return compliance;
        }
      );
      this.handleChange();
    },
    getErrorMessages(errors) {
      return this.showValidationErrors ? errors : "";
    },
    resetValidations() {
      if (first(this.$refs.HCPCNumber)) {
        first(this.$refs.HCPCNumber).$refs.validation.reset();
      }
      if (first(this.$refs.QTSNumber)) {
        first(this.$refs.QTSNumber).$refs.validation.reset();
      }
      if (first(this.$refs.barredListDate)) {
        first(this.$refs.barredListDate).$refs.validation.reset();
      }
      if (first(this.$refs.hsCompletionDate)) {
        first(this.$refs.hsCompletionDate).$refs.validation.reset();
      }
      if (first(this.$refs.ctCompletionDate)) {
        first(this.$refs.ctCompletionDate).$refs.validation.reset();
      }
      if (first(this.$refs.RFExpiryDate)) {
        first(this.$refs.RFExpiryDate).$refs.validation.reset();
      }
      if (this.$refs.dbsValidation) {
        this.$refs.dbsValidation.reset();
      }
      if (first(this.$refs.gdprtCompletionDate)) {
        first(this.$refs.gdprtCompletionDate).$refs.validation.reset();
      }
      if (first(this.$refs.EDTCompletionDate)) {
        first(this.$refs.EDTCompletionDate).$refs.validation.reset();
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
